<template>
    <div class="d-inline-block d-flex align-center">
        <v-btn icon color="primary" elevation="1" @click="decrease(-1)"><v-icon>mdi-minus</v-icon></v-btn>
        <v-text-field v-model="quantity" type="number" outlined hide-details dense class="mx-2" style="width: 150px;" @input="update()"/>
        <v-btn icon color="primary" elevation="1" @click="increase(1)"><v-icon>mdi-plus</v-icon></v-btn>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Number, default: 0 }
    },
    data(){
        return {
            quantity: this.$props.value
        }
    },
    methods: {
        increase(quantity){
            this.quantity += quantity;
            this.update();
        },
        decrease(quantity){
            this.quantity += quantity;
            this.update();
        },
        update(){
            if(this.quantity < 0) this.quantity = 0;
            this.quantity = parseInt(this.quantity);
            this.$emit("input", this.quantity);
        },
    }
}
</script>

<style scoped>
.v-text-field >>> input { 
    text-align: center;
}
</style>